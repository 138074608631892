import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  HandoverList,
  HandoverDetail,
  PartnerVendorList
} from "@/domain/entities/Handover";
import { HandoverPresenter } from "@/app/ui/presenters/HandoverPresenter";
import { SubconsoleEntities } from "@/domain/entities/Subconsole";
import { container } from "tsyringe";
import {
  ResponsePayload,
  ResponseSttDetail
} from "@/domain/entities/ResponsePayload";
import {
  RequestListHandover,
  RequestPartnerVendorList,
  UpdateApiRequest
} from "@/data/payload/api/HandoverApiRequest";
import { MainAppController } from "./MainAppController";
import { CLIENT_ERROR, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";

export interface HandoverState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  handoverList: HandoverList;
  subconsoleList: SubconsoleEntities;
}

@Module({ namespaced: true, dynamic: true, store, name: "handover" })
class HandoverStore extends VuexModule implements HandoverState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isLoadingSttDetail = false;
  public isLoadingSubConsole = false;
  public isError = false;
  public errorCause = "";
  public filter = new RequestListHandover();
  public handoverList = new HandoverList(new Pagination(1, 10), []);
  public subconsoleList = new SubconsoleEntities(new Pagination(1, 10), []);
  public detailHandover = new HandoverDetail();
  public isLoadingPartnerVendor = false;
  public partnerVendorList: Array<PartnerVendorList> = [];

  @Action
  public getHandoverList(params: RequestListHandover) {
    this.setIsLoading(true);
    const presenter = container.resolve(HandoverPresenter);
    presenter
      .getHandoverList(params)
      .then((res: HandoverList) => {
        this.setHandoverList(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setHandoverList(new HandoverList(new Pagination(0, 0), []));
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  @Action
  public fetchHandoverList() {
    this.getHandoverList(
      new RequestListHandover({
        page: this.handoverList.pagination.page,
        limit: this.handoverList.pagination.limit,
        startDate: new Date(this.filter.startDate).toLocaleDateString("fr-CA"),
        endDate: new Date(this.filter.endDate).toLocaleDateString("fr-CA"),
        destinationPartnerId: this.filter.destinationPartnerId,
        search: this.filter.search,
        sortBy: this.filter.sortBy,
        orderBy: this.filter.orderBy
      })
    );
  }

  @Action
  public getDetailHandover(handoverId: number) {
    this.setIsLoadingDetail(true);
    const presenter = container.resolve(HandoverPresenter);
    return presenter
      .getDetailHandover(handoverId)
      .then((res: HandoverDetail) => {
        this.setError(false);
        this.setErrorCause("");
        this.setDetailHandover(res);
        return true;
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setIsLoadingDetail(false);
      });
  }

  @Action
  public getListSubconsole(params: { parentId: number }) {
    this.setIsLoadingSubConsole(true);
    const presenter = container.resolve(HandoverPresenter);
    presenter
      .getListSubconsole(params.parentId)
      .then((res: SubconsoleEntities) => {
        this.setSubconsoleList(res);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        this.setIsLoadingSubConsole(false);
      });
  }

  @Action
  public getPartnerVendor(params: RequestPartnerVendorList) {
    this.setLoadingPartnerVendor(true);
    this.setPartnerVendorList([]);
    const presenter = container.resolve(HandoverPresenter);
    presenter
      .getPartnerVendorList(params)
      .then((res: PartnerVendorList[]) => this.setPartnerVendorList(res))
      .finally(() => this.setLoadingPartnerVendor(false));
  }

  @Action
  public getSttDetail(params: { stt: string; callback: any, bagNo:string, partnerCode:string, noRef?: string}) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(HandoverPresenter);
    return presenter
      .getSttDetail(params.stt, params.bagNo, params.partnerCode)
      .then((res: ResponseSttDetail) => {
        params.callback(res, params.noRef);
        return res;
      })
      .catch((err: any) => {
        const errorMessage = err.response.data.message.id;
        const isJumbopackH2H = errorMessage === "STT gagal discan karena paket akan diberi status POD setelah diambil oleh penerima.";
        playNotification("error");
        if (isJumbopackH2H) {
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "Paket akan diambil penerima",
              message: errorMessage,
              onClose: () => MainAppController.closeErrorMessage(),
              buttonSuccessText: "Oke, mengerti"
            })
          );
        }
        else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
              this.getSttDetail(params)
            )
          );
        }
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public onUpdate(params: UpdateApiRequest) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(HandoverPresenter);
    return presenter
      .updateHandover(params)
      .then((res: ResponsePayload) => {
        return res.data;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Status Gagal!", () =>
            this.onUpdate(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  private setHandoverList(data: HandoverList) {
    this.handoverList = data;
  }

  @Mutation
  private setPartnerVendorList(data: PartnerVendorList[]) {
    this.partnerVendorList = data;
  }

  @Mutation
  public setSubConsoleId(subConsoleId: string) {
    this.filter.destinationPartnerId = subConsoleId;
  }

  @Mutation
  public setSearch(id: string) {
    this.handoverList.pagination.page = 1;
    this.filter = new RequestListHandover({
      ...this.filter,
      search: id,
      sortBy: id ? "handover_id" : "",
      orderBy: id ? "ASC" : ""
    });
  }

  @Mutation
  private setSubconsoleList(data: SubconsoleEntities) {
    this.subconsoleList = data;
  }

  @Mutation
  private setDetailHandover(data: HandoverDetail) {
    this.detailHandover = data;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setLoadingSttDetail(value: boolean) {
    this.isLoadingSttDetail = value;
  }

  @Mutation
  public setIsLoadingDetail(value: boolean) {
    this.isLoadingDetail = value;
  }

  @Mutation
  public setIsLoadingSubConsole(value: boolean) {
    this.isLoadingSubConsole = value;
  }

  @Mutation
  public setLoadingPartnerVendor(value: boolean) {
    this.isLoadingPartnerVendor = value;
  }

  @Mutation
  public setFilter(params: RequestListHandover) {
    this.filter = params;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }
}

export const HandoverController = getModule(HandoverStore);
