
import { Vue } from "vue-class-component";
import {
  convertDecimalWithComma,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { HandoverController } from "@/app/ui/controllers/HandoverController";
import { Handover } from "@/domain/entities/Handover";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { SubconsoleData } from "@/domain/entities/Subconsole";
import { RequestListHandover } from "@/data/payload/api/HandoverApiRequest";
import { FlagsPermissionHandover } from "@/feature-flags/flags-permission-handover";

export default class HandoverList extends Vue {
  get getTitle() {
    return this.$route.meta.title;
  }
  get ableToCreate() {
    return FlagsPermissionHandover.handover_create.isEnabled();
  }
  get ableToDetail() {
    return FlagsPermissionHandover.handover_detail_view.isEnabled();
  }
  mounted() {
    this.getListSubconsole();
  }
  unmounted() {
    HandoverController.setFilter(new RequestListHandover());
  }

  subconsole = "";

  fetchHandoverList() {
    return HandoverController.fetchHandoverList();
  }

  // filter search
  get searchValue() {
    return HandoverController.filter.search;
  }
  onSearch(value: string) {
    HandoverController.setSearch(value);
    this.fetchHandoverList();
  }
  clearSearch() {
    HandoverController.setSearch("");
    this.fetchHandoverList();
  }

  get subConsoleList() {
    const res = [{ name: "Pilih Sub Consolidator", value: 0 }];
    HandoverController.subconsoleList.data.forEach((key: SubconsoleData) => {
      res.push({ name: key.name, value: key.id });
    });
    return res;
  }

  getListSubconsole() {
    return HandoverController.getListSubconsole({
      parentId: Number(this.parentId)
    });
  }

  isOpenSelectSubConsole = false;
  onOpenSelectSubConsole() {
    this.isOpenSelectSubConsole = true;
  }

  onCloseSelectSubConsole() {
    this.isOpenSelectSubConsole = false;
  }

  onSelectSubConsole(name: string, value: string) {
    this.onCloseSelectSubConsole();
    HandoverController.setSubConsoleId(value);
    this.fetchHandoverList();
  }

  // loading
  get isLoading() {
    return HandoverController.isLoading;
  }

  // error cause
  get errorCause() {
    return HandoverController.errorCause;
  }

  // filter date
  get startDate() {
    return HandoverController.filter.startDate;
  }

  get endDate() {
    return HandoverController.filter.endDate;
  }

  get parentId() {
    return AccountController.accountData.account_type_detail.id;
  }

  get destinationPartnerId(): any {
    return HandoverController.filter.destinationPartnerId;
  }

  setDateRange(value: Array<any>) {
    HandoverController.setFilter(
      new RequestListHandover({
        startDate: value[0],
        endDate: value[1]
      })
    );
    value[0] && value[1] && this.fetchHandoverList();
  }

  get pagination() {
    return HandoverController.handoverList.pagination;
  }

  get handoverList() {
    return HandoverController.handoverList.data;
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (_: Handover, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "ID Manifest",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: Handover) => {
          return `<div class="overflow-ellipsis text-left">${item.handoverId}</div>`;
        }
      },
      {
        name: "Diserahkan ke",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: Handover) => {
          return `<div class="overflow-ellipsis text-left">${
            item.handoverDestinationPartnerName !== ""
              ? item.handoverDestinationPartnerName
              : item.handoverVendorCode
          }</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: Handover) => {
          return `<div class="overflow-ellipsis text-left">${item.handoverTotalSTT}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: Handover) => {
          return `<div class="overflow-ellipsis text-left">${item.handoverTotalPiece}</div>`;
        }
      },
      {
        name: "Total Gross Weight",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: Handover) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.handoverTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Total Volume Weight",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: Handover) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.handoverTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal Handover",
        styleHead: "w-64 text-left",
        render: (item: Handover) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.handoverCreatedAt
          )}</div>
            <div class="capitalize text-left text-gray-lp-500">${
              item.handoverCreatedBy
            }</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-24 text-left",
        styleCustom: "padding-action-sti",
        styleButton: (item: Handover) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option-sti",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "print-summary",
                label: "Print Summary",
                clickFunction: () => this.print(item.handoverId, "summary")
              },
              {
                icon: "printer",
                label: "Print PDF",
                clickFunction: () => this.print(item.handoverId, "basic")
              }
            ]
          };
        }
      }
    ];
  }

  async print(handoverId: number, type: string) {
    const book: any = await import("./modules/print.vue");
    const printType: any = {
      summary: () =>
        book.default.methods.printSummary(this.sourceImage, handoverId),
      basic: () => book.default.methods.print(this.sourceImage, handoverId)
    };

    printType[type]();
  }

  get sourceImage() {
    return (
      (AccountController.accountData.account_type === "partner"
        ? AccountController.accountData.account_type_detail.type
        : AccountController.accountData.account_type) || "internal"
    );
  }

  onClickRow(item: Handover) {
    this.$router.push(
      `/handover/${item.handoverId}?params=Handover Manifest - ${item.handoverId}`
    );
  }

  goToUpdate() {
    this.$router.push("/handover/update");
  }
}
