/* eslint-disable @typescript-eslint/camelcase */
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { UpdateHandoverRequestInterface } from "../contracts/HandoverRequest";
import { getHubData } from "@/app/infrastructures/misc/Cookies";

export class UpdateApiRequest implements UpdateHandoverRequestInterface {
  destinationPartnerId = 0;
  destinationPartnerCode = "";
  destinationPartnerType = "";
  destinationCityCode = "";
  remarks = "";
  handoverSttNo = [] as Array<string>;
  constructor(fields?: Partial<UpdateApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON(): string {
    return JSON.stringify({
      destination_partner_id: this.destinationPartnerId,
      destination_partner_code: this.destinationPartnerCode,
      destination_partner_type: this.destinationPartnerType,
      destination_city_code: this.destinationCityCode,
      remarks: this.remarks,
      handover_stt_no: this.handoverSttNo,
      hub_id: getHubData()?.hubId || 0,
      hub_name: getHubData()?.hubName || "",
      hub_origin_city: getHubData()?.originCity || "",
      hub_district_code: getHubData()?.hubDistrictCode || ""
    });
  }
}

export class RequestListHandover {
  page = 1;
  limit = 10;
  search = "";
  sortBy = "";
  orderBy = "";
  startDate = "";
  endDate = "";
  destinationPartnerId = "";
  version = "v1";
  constructor(fields?: Partial<RequestListHandover>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class RequestPartnerVendorList {
  page = 1;
  limit = 10;
  search = "";
  constructor(fields?: Partial<RequestPartnerVendorList>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
